import React from 'react';
import Card from '@mui/material/Card';
import './AccessDenied.css'; // You can create a CSS file for styling if needed

const AccessDenied = () => {
  return (
    <div className='access-denied-container'>
      <Card className='access-denied-card'>
        <div className='access-denied-header'>Access Denied</div>
        <div className='access-denied-message'>You do not have permission to access this page.</div>
      </Card>
    </div>
  );
}

export default AccessDenied;

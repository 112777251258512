import React, { useCallback, useEffect, useState } from 'react';
import './ClientSites.css';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Bangalore from '../../../assets/images/Logo_Bangalore.png';
import CSK from '../../../assets/images/Logo_Chennai.png';
import NCR from '../../../assets/images/Logo_Delhi.png';
import Pune from '../../../assets/images/Logo_Pune_Lion.png';
import Mumbai from '../../../assets/images/Logo_mumbai.png';
import HYD from '../../../assets/images/Logo_Hyderabad.png';
import cityLeague from '../../../assets/images/New_city_league.png';
import lithiumCar from '../../../assets/images/Car.svg';


const CITY_IMAGES = {
  "Royal Challengers Bangalore ": Bangalore,
  "Chennai Super Kings": CSK,
  "Delhi Daredevils": NCR,
  "Salted Storm Pune": Pune,
  "Mumbai Paltan": Mumbai,
  "Hyderabadi Nawaabs": HYD,
};

const ClientSites = ({ leadingClientsData, onsubmit }) => {
  const [selectedTeam, setSelectedTeam] = useState('Banglore');
  const [color, setColor] = useState('')
  useEffect(()=>{
    setColor('purple')
    setSelectedTeam("Royal Challengers Bangalore ")
    onsubmit('purple');
  },[onsubmit])

  const handleTeamChange = useCallback((event) => {
    setSelectedTeam(event.target.value);
    let color= "red"
    console.log(event.target.value, "event.target.value1")
    if(event.target.value === "Royal Challengers Bangalore "){
      color = "purple"
    }else if(event.target.value === "Chennai Super Kings"){
      color= "#084D83"
    }else if(event.target.value === "Salted Storm Pune"){
      color= "#0380C3"
    }else if(event.target.value === "Mumbai Paltan"){
      color= "#db352f"
    }else if(event.target.value === "Delhi Daredevils"){
      color= "#453780"
    }else if(event.target.value === "Hyderabadi Nawaabs"){
      color= "#e5ab17"
    }
    setColor(color)
    onsubmit(color);
  },[onsubmit]);

  const filteredData = leadingClientsData?.leading_clients_data?.filter(client => client?.Team === selectedTeam);
  
  return (
    <div className='client-sites-container' >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} className='client-sites-container-grid' style={{background:color}}>
          <Grid item xs={2.8}>
            <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <img src={filteredData?.length > 0 ? CITY_IMAGES[filteredData[0]?.Team] : ""} alt="league" className='client-sites-container-image' />
            </div>
          </Grid>
          <Grid item xs={7.2}>
          <div className='client-sites-container-title'>LEADING CLIENT SITES</div>
          <div className='client-sites-container-sub-title'>{ leadingClientsData?.leading_clients_data[0]?.Week}</div>
          <select name="Team" id="Team" className='client-sites-container-select' onChange={handleTeamChange} value={selectedTeam}>
              <option value="Chennai Super Kings">Chennai Super Kings</option>
              <option value="Royal Challengers Bangalore ">Royal Challengers Bangalore</option>
              <option value="Salted Storm Pune">Salted Storm Pune</option>
              <option value="Mumbai Paltan">Mumbai Paltan</option>
              <option value="Delhi Daredevils">Delhi Daredevils</option>
              <option value="Hyderabadi Nawaabs">Hyderabadi Nawaabs</option>
            </select>
          </Grid>
          <Grid item xs={2}>
          <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
              <img src={cityLeague} alt="league" className="city-league-image" />
            </div>
          </Grid>
        </Grid>
      </Box>

      <div className='client-sites-table-container'>
        <div> 
        <table className='client-sites-container-table'>
          <tbody>
            {filteredData&& filteredData?.map((client, index) => (
              <tr key={index}>
                <td className='client-sites-container-table-row-one' style={{ border: `10px solid ${color}` }}>{client.Rank}</td>
                <td className='client-sites-container-table-row-two' style={{ border: `10px solid ${color}` }}>{client["Client Name"]}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        <div className='lithium-car-image-container'>
        <img src={lithiumCar} alt="league" className="lithium-car-image" />
          </div>
      </div>
    </div>
  )
}

export default ClientSites;

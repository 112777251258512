import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LithiumLogo from '../../assets/images/LogoIcon1.png';
import LithiumEvLogo from '../../assets/images/LogoIcon.png';
import './Header.css'

export default function Header() {
  return (
    <div className='header'>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky">
        <Toolbar style={{background:"rgb(47, 171, 146)"}}>
        <img src={LithiumEvLogo} alt="Ev Vehicle logo" className='feed-back-ev-logo-image' />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          </Typography>
          <div className='feed-back-ev-logo-container'>
          <img src={LithiumLogo} alt="Ev Vehicle" className='feed-back-logo-image' />
          </div>
        </Toolbar>
      </AppBar>
    </Box>
    </div>
  );
}
import * as React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

const labels = {
  1: 'Good',
  2: 'Good',
  3: 'Good',
  4: 'Very Good',
  5: 'Excellent',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

export default function StarRating(props) {
  const [value, setValue] = React.useState(3);
  const [hover, setHover] = React.useState(-1);
  
  const handleSubmit = React.useCallback(() => {
    props.submit(value, props.ratingPage);
  }, [value, props]);

  return (
    <div>
      <Box
        sx={{
          '& > legend': { mt: 2 },
        }}
      >
        <Typography variant="h6" gutterBottom>
          Please rate your experience:
        </Typography>
        <Rating
          name="simple-controlled"
          value={value}
          onChange={(event, newValue) => {
            // Ensure the user cannot select below 3 stars
            if (newValue >= 3) {
              setValue(newValue);
            }
          }}
          getLabelText={getLabelText}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          sx={{
            '& .MuiRating-icon': {
              fontSize: '60px',
              // Your custom styling for stars
              // Note: Ensure proper CSS syntax here
              color: 'radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)',
              '@media (max-width: 768px)': {
                fontSize: '60px',
                margin: '10px 0px',
                alignItems: 'center',
              },
            },
          }}
        />
        {value !== null && (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "18px", fontWeight: "bolder" }}>{labels[hover !== -1 ? hover : value]}</div>
        )}
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "5px" }}>
          <Button
            variant='contained'
            // style={{ background: "black" }}
            className='submit-button'
            onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Box>
    </div>
  );
}

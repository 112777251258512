import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import EvVehicleImage from '../../assets/images/Car2.jpeg';
import './LoginPage.css';
import FeedbackForm from '../feedback-form/FeedbackForm';

export default function LoginPage() {

  const [showFeedBack, setShowFeedback] = React.useState("showLogin")

  React.useEffect(() => {
    localStorage.clear()
  }, []);


  return (
    <div>
      {showFeedBack === "showLogin" ? <div className='login-page-container'>
      <Card className='login-page-card' style={{boxShadow:"none"}}>
        <img src={EvVehicleImage} alt="Ev Vehicle" className='feed-back-ev-icon' />
        <div style={{marginTop:"45px"}}>
        <div className='login-page-container-title'>Help Us Improve</div>
        <CardActions style={{ display:"inline", justifyContent: 'center', alignItems:"center"}}>
            <Button onClick={()=>setShowFeedback("showSafetyFeedback")} variant='contained' fullWidth className='login-page-button' style={{marginBottom:"20px"}}>Ride Safety</Button>
            <Button onClick={()=>setShowFeedback("showRideFeedback")} variant='contained' className='login-page-button'>Ride Experience</Button>
        </CardActions>
        </div>
      </Card>
      </div>:<FeedbackForm value={showFeedBack}/> }
    </div>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import './FeedbackForm.css';
import ThankYouPage from '../thankyou/ThankYouPage';
import AccessDenied from '../../components/AccessDenied/AccessDenied';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import StarRating from '../../components/star-rating/StarRating';
// import EvVehicleImage from '../../assets/images/LithiumImage.png';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const feedbackQuestions = {
  showSafetyFeedback: [
    { id: 1, question: "Did you have a safe ride?" },
    { id: 2, question: "Was your driver alert?" },
    { id: 3, question: "Was the driver using their mobile phone for anything other than navigation?" },
    { id: 4, question: "Was the vehicle in good condition?" }
  ],
  showRideFeedback: [
    { id: 5, question: "Did you have a comfortable ride?" },
    { id: 6, question: "Was the car clean, both inside and outside and free of odor?" },
    { id: 7, question: "Was the air conditioning satisfactory?" },
    { id: 8, question: "Were you satisfied with the quality of driving?" }
  ]
};

const FeedbackForm = ({ value }) => {
  const { regNumber } = useParams(); // Extract regNumber from URL

  const [answers, setAnswers] = useState({}); // State to store user answers
  const [showThankYouPage, setShowThankYouPage] = useState(false);
  const [buttonSelections, setButtonSelections] = useState({});
  const [ipAddress, setIpAddress] = useState(null); // State to store IP address 
  const [validationMessage, setValidationMessage] = useState(''); // State for validation message
  const [showAllQuestions, setShowAllQuestions] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [payload, setPayload] = React.useState({
    "reg_number": regNumber,
    "answer": {
      "safety": {
        "safe_ride": null,
        "experience_rating": null,
        "issues": {
          "driver_alert": null,
          "driver_phone_usage": null,
          "vehicle_condition": null
        }
      },
      "ride": {
        "comfortable_ride": null,
        "experience_rating": null,
        "issues": {
          "car_cleanliness": null,
          "ac_temperature_control": null,
          "speeding_rash_driving": null
        }
      }
    },
    "unique": {
      "ip_address": ipAddress
    }
  });

  const fetchIpAddress = useCallback(async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      const fetchedIpAddress = response.data.ip;
      setIpAddress(fetchedIpAddress);
      setPayload((prevPayload) => ({
        ...prevPayload,
        unique: {
          ip_address: fetchedIpAddress
        }
      }));
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  }, []);

  const handleClose = () => setOpen(false);

  // Function to handle user input changes
  const handleInputChange = useCallback((questionId, answer) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: answer
    }));

    if (answer === 'Yes' || answer === 'No') {
      setButtonSelections(prevSelections => ({
        ...prevSelections,
        [questionId]: answer
      }));
    }

    if ((questionId === 1 && answer === 'Yes') || (questionId === 5 && answer === 'Yes')) {
      setOpen(true);
    } else if ((questionId === 1 && answer === 'No') || (questionId === 5 && answer === 'No')) {
      setShowAllQuestions(true);
    }
  }, []);


  useEffect(() => {
    // Check if all relevant answers are present
    const safetyQuestionsAnswered =
      answers.hasOwnProperty(1) &&
      answers.hasOwnProperty(2) &&
      answers.hasOwnProperty(3) &&
      answers.hasOwnProperty(4);

    const rideQuestionsAnswered =
      answers.hasOwnProperty(5) &&
      answers.hasOwnProperty(6) &&
      answers.hasOwnProperty(7) &&
      answers.hasOwnProperty(8);

    // Determine which set of questions is answered based on 'value'
    const questionsAnswered =
      (value === "showSafetyFeedback" && safetyQuestionsAnswered) ||
      (value === "showRideFeedback" && rideQuestionsAnswered);

    if (questionsAnswered) {
      setValidationMessage('');
      // Prepare newPayload based on 'value' and answers
      const newPayload = {
        ...payload,
        answer: {
          ...payload.answer,
          safety: value === "showSafetyFeedback" ? {
            safe_ride: answers[1],
            experience_rating: answers.page || null,
            issues: {
              driver_alert: answers[2],
              driver_phone_usage: answers[3],
              vehicle_condition: answers[4]
            }
          } : payload.answer.safety,
          ride: value === "showRideFeedback" ? {
            comfortable_ride: answers[5],
            experience_rating: answers.page || null,
            issues: {
              car_cleanliness: answers[6],
              ac_temperature_control: answers[7],
              speeding_rash_driving: answers[8]
            }
          } : payload.answer.ride
        }
      };
      setPayload((prev) => ({ ...prev, ...newPayload }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers, value]); // Depend on 'answers' and 'value'


  const handleStarSubmit = useCallback((value, page) => {
    setOpen(false);
    setShowThankYouPage(true);
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      page: value
    }));

    const newPayload = {
      ...payload,
      answer: {
        ...payload.answer,
        safety: page === 'showSafetyFeedback' ? {
          ...payload.answer.safety,
          safe_ride: "Yes",
          experience_rating: value
        } : payload.answer.safety,
        ride: page === 'showRideFeedback' ? {
          ...payload.answer.ride,
          comfortable_ride: "Yes",
          experience_rating: value
        } : payload.answer.ride
      }
    };
    setPayload((prev) => ({ ...prev, ...newPayload }));
    localStorage.setItem(page, JSON.stringify({ ...payload, ...newPayload }));

  }, [payload]);

  const handleSubmit = useCallback(() => {
    const safetyQuestionsAnswered =
      answers.hasOwnProperty(2) &&
      answers.hasOwnProperty(3) &&
      answers.hasOwnProperty(4);

    const rideQuestionsAnswered =
      answers.hasOwnProperty(6) &&
      answers.hasOwnProperty(7) &&
      answers.hasOwnProperty(8);

    // Validate that all required questions are answered
    if (
      (value === "showSafetyFeedback" && !safetyQuestionsAnswered) ||
      (value === "showRideFeedback" && !rideQuestionsAnswered)
    ) {
      setValidationMessage('Please answer all questions before submitting.');
      return;
    }

    // Perform API call
    localStorage.setItem(value, JSON.stringify(payload));
    setShowThankYouPage(true);
  }, [answers, payload, value]);

  useEffect(() => {
    fetchIpAddress();
  }, [fetchIpAddress]);

  if (!regNumber) {
    return <AccessDenied />;
  }

  const questions = feedbackQuestions[value];

  return (
    <div>
      {showThankYouPage ? <ThankYouPage value={value} /> :
        <div className='feedback-form-container'>
          <div className='feedback-form-container-card'>
            <div className='feedback-form-header'>FEEDBACK FORM</div>
            <div className='feedback-form-sub-header'>
              {value === "showSafetyFeedback" ? "Safety is paramount to us!" : "How was your Lithium ride?"}
            </div>
            {/* <div className='feedback-form-sub-title'>
              {value === "showSafetyFeedback" ?
                "Answer a few questions about your recent trip. (Safety Feedback)" :
                <><div>Share your thoughts in a quick survey!</div> <div>(Ride Experience)</div></>
              }
            </div> */}
            
            {value === "showSafetyFeedback" ?
              !showAllQuestions ? <div style={{ marginTop: "150px" }}>
                <Card className='feedback-card-container' style={{height: "250px"}}>
                  <div className='feedback-card-line-container'>
                    <div className='feedback-card-line-container-one'></div>
                    <div className='feedback-card-line-container-two'></div>
                  </div>
                  <div className='feedback-form-question-container' style={{marginTop:"70px"}}>
                    <div className='feedback-form-question' style={{ fontSize:"20px"}}>Did you have a safe ride?</div>
                    <div className='feedback-form-submit-container'>
                      <Button
                        variant='contained'
                        className='feedback-form-submit'
                        onClick={() => handleInputChange(1, 'Yes')}
                        style={{ backgroundColor: buttonSelections[1] === 'Yes' ? '#2ab7a3' : 'inherit' }}
                      > Yes
                      </Button>
                      <Button
                        size="large"
                        variant='contained'
                        onClick={() => handleInputChange(1, 'No')}
                        className='feedback-form-submit'
                        style={{ backgroundColor: buttonSelections[1] === 'No' ? '#2ab7a3' : 'inherit' }}
                      >
                        No
                      </Button>
                    </div>
                  </div>
                  {/* <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:"80px"}}>
                  <img src={EvVehicleImage} alt="Ev Vehicle" className='feed-back-ev-icon' />
                  </div> */}
                </Card>
              </div> : <div style={{ marginTop: "10px" }}>
                <Card className='feedback-card-container'>
                  <div className='feedback-card-line-container'>
                    <div className='feedback-card-line-container-two'></div>
                    <div className='feedback-card-line-container-one'></div>
                  </div>
                  <div className='feedback-form-question-title'>Help us get better</div>
                  {questions.map(question => (
                    question.id !== 1 && (
                      <div key={question.id} className='feedback-form-question-container'>
                        <div className='feedback-form-question'>{question.question}</div>
                        <div className='feedback-form-submit-container'>
                          <Button
                            variant='contained'
                            className='feedback-form-submit'
                            onClick={() => handleInputChange(question.id, 'Yes')}
                            style={{ backgroundColor: buttonSelections[question.id] === 'Yes' ? '#2ab7a3' : 'inherit' }}
                          >
                            Yes
                          </Button>
                          <Button
                            size='large'
                            variant='contained'
                            onClick={() => handleInputChange(question.id, 'No')}
                            className='feedback-form-submit'
                            style={{ backgroundColor: buttonSelections[question.id] === 'No' ? '#2ab7a3' : 'inherit' }}
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    )))}
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "15px" }}>
                    <Button
                      variant='contained'
                      size='large'
                     className='submit-button'
                      onClick={() => handleSubmit()}>
                      Submit
                    </Button>
                  </div>
                  {validationMessage !== '' && <div className='feedback-form-error-message'>{validationMessage}</div>}
                </Card>
              </div> :
              !showAllQuestions ? <div style={{ marginTop: "150px" }}>
                <Card className='feedback-card-container'  style={{height: "250px"}}>
                  <div className='feedback-card-line-container'>
                    <div className='feedback-card-line-container-one'></div>
                    <div className='feedback-card-line-container-two'></div>
                  </div>
                  <div className='feedback-form-question-container' style={{ marginTop:"70px"}}>
                    <div className='feedback-form-question' style={{ fontSize:"19px"}}>Did you have a comfortable ride?</div>
                    <div className='feedback-form-submit-container'>
                      <Button
                        variant='contained'
                        className='feedback-form-submit'
                        onClick={() => handleInputChange(5, 'Yes')}
                        style={{ backgroundColor: buttonSelections[5] === 'Yes' ? '#2ab7a3' : 'inherit' }}
                      > Yes
                      </Button>
                      <Button
                        size="large"
                        variant='contained'
                        onClick={() => handleInputChange(5, 'No')}
                        className='feedback-form-submit'
                        style={{ backgroundColor: buttonSelections[5] === 'No' ? '#2ab7a3' : 'inherit' }}
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </Card>
              </div> : <div style={{ marginTop: "10px" }}>
                <Card className='feedback-card-container'>
                  <div className='feedback-card-line-container'>
                    <div className='feedback-card-line-container-two'></div>
                    <div className='feedback-card-line-container-one'></div>
                  </div>
                  <div className='feedback-form-question-title'>Help us get better</div>
                  {questions.map(question => (
                    question.id !== 5 && (
                      <div key={question.id} className='feedback-form-question-container'>
                        <div className='feedback-form-question'>{question.question}</div>
                        <div className='feedback-form-submit-container'>
                          <Button
                            variant='contained'
                            className='feedback-form-submit'
                            onClick={() => handleInputChange(question.id, 'Yes')}
                            style={{ backgroundColor: buttonSelections[question.id] === 'Yes' ? '#2ab7a3' : 'inherit' }}
                          >
                            Yes
                          </Button>
                          <Button
                            size='large'
                            variant='contained'
                            onClick={() => handleInputChange(question.id, 'No')}
                            className='feedback-form-submit'
                            style={{ backgroundColor: buttonSelections[question.id] === 'No' ? '#2ab7a3' : 'inherit' }}
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    )))}
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                    <Button
                      variant='contained'
                      size='large'
                      className='submit-button'
                      onClick={() => handleSubmit()}>
                      Submit
                    </Button>
                  </div>
                  {validationMessage !== '' && <div className='feedback-form-error-message'>{validationMessage}</div>}
                </Card>
              </div>}
          </div>
        </div>}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <StarRating submit={handleStarSubmit} ratingPage={value} />
        </Box>
      </Modal>
    </div>
  );
}

export default FeedbackForm;

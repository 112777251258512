import React, { useCallback, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import './ThankYouPage.css';
import LoginPage from '../login-page/LoginPage';
import FeedbackForm from '../feedback-form/FeedbackForm';
import axios from 'axios';

export default function ThankYouPage({ value }) {

  const [showLoginPage] = useState(false);
  const [showThanksMessage, setShowThanksMessage] = useState(false);
  const [showFormPage, setShowFormPage] = useState(false);

  const handleThankYouPageLink = useCallback(() => {
    setShowFormPage(value);
  }, [value]);

  React.useEffect(() => {
    const form1Filled = JSON.parse(localStorage.getItem('showSafetyFeedback'));
    const form2Filled = JSON.parse(localStorage.getItem('showRideFeedback'));
    if (form1Filled && form2Filled) {
      setShowThanksMessage(true);
      const mergePayloads = {
        reg_number: form1Filled.reg_number,
        answer: {
          safety: {
            safe_ride: form1Filled.answer.safety.safe_ride || form2Filled.answer.safety.safe_ride,
            experience_rating: form1Filled.answer.safety.experience_rating || form2Filled.answer.safety.experience_rating,
            issues: {
              driver_alert: form1Filled.answer.safety.issues.driver_alert || form2Filled.answer.safety.issues.driver_alert,
              driver_phone_usage: form1Filled.answer.safety.issues.driver_phone_usage || form2Filled.answer.safety.issues.driver_phone_usage,
              vehicle_condition: form1Filled.answer.safety.issues.vehicle_condition || form2Filled.answer.safety.issues.vehicle_condition,
            }
          },
          ride: {
            comfortable_ride: form1Filled.answer.ride.comfortable_ride || form2Filled.answer.ride.comfortable_ride,
            experience_rating: form1Filled.answer.ride.experience_rating || form2Filled.answer.ride.experience_rating,
            issues: {
              car_cleanliness: form1Filled.answer.ride.issues.car_cleanliness || form2Filled.answer.ride.issues.car_cleanliness,
              ac_temperature_control: form1Filled.answer.ride.issues.ac_temperature_control || form2Filled.answer.ride.issues.ac_temperature_control,
              speeding_rash_driving: form1Filled.answer.ride.issues.speeding_rash_driving || form2Filled.answer.ride.issues.speeding_rash_driving,
            }
          }
        },
        unique: {
          ip_address: form1Filled.unique.ip_address || form2Filled.unique.ip_address,
        }
      };
      axios.post('https://ritaprodapi.project-lithium.com/noc-service/submit_ride_safty_feedback', mergePayloads)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.error('Error submitting feedback:', error);
        })

    }
  }, []);

  const handleCloseButtonClick = () => {
    if (!showThanksMessage) {
      setShowThanksMessage(true);

      const form1Filled = JSON.parse(localStorage.getItem('showSafetyFeedback'));
      const form2Filled = JSON.parse(localStorage.getItem('showRideFeedback'));
      axios.post('https://ritaprodapi.project-lithium.com/noc-service/submit_ride_safty_feedback', form1Filled || form2Filled)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.error('Error submitting feedback:', error);
        })
    } else {
      // Redirect to external URL
      window.location.href = 'https://project-lithium.com';
    }
  };

  return (
    <div>
      {showLoginPage ? (
        <LoginPage />
      ) : (
        <div>
          {showFormPage === 'showRideFeedback' ? (
            <FeedbackForm value={'showSafetyFeedback'} />
          ) : showFormPage === 'showSafetyFeedback' ? (
            <FeedbackForm value={'showRideFeedback'} />
          ) : (
            <div className='thank-you-page-container'>
              <Card className='thank-you-page-card'>
                <CardContent>
                  <div className='thank-you-page-title'>
                    {showThanksMessage ? "Thank you for your time!" : "Thank you for the feedback"}
                  </div>
                  <div className='thank-you-page-sub-title'>
                    {showThanksMessage ? "Your input is important to us and will help us enhance your experience." : "We would really appreciate it if you could also complete the second section."}
                  </div>
                  {!showThanksMessage && (
                    <div className='thank-you-page-link' onClick={handleThankYouPageLink}>
                      {value === "showRideFeedback" ? "Safety of the Ride feedback form" : "Ride experience feedback form"}
                    </div>
                  )}
                </CardContent>
                <CardActions>
                  <Button
                    size='large'
                    onClick={handleCloseButtonClick}
                    className='thank-you-page-submit'
                    variant='contained'
                    fullWidth
                  >
                    Close
                  </Button>
                </CardActions>
              </Card>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

// Home component (Home.js)

import React from 'react';
import './Home.css';
import LoginPage from '../login-page/LoginPage';
import Header from '../../components/Header/Header';

export default function Home() {
  return (
    <div>
      <Header />
      <div className="content">
        <LoginPage />
      </div>
    </div>
  );
}

import React from 'react';
import './CityLeagueStandings.css';
import Bangalore from '../../../assets/images/Bangalore.png';
import Chennai from '../../../assets/images/Chennai.png';
import NCR from '../../../assets/images/Delhi.png';
import Pune from '../../../assets/images/Pune.png';
import Mumbai from '../../../assets/images/Mumbai.png';
import HYD from '../../../assets/images/Hyderabad.png';
import cityLeague from '../../../assets/images/New_city_league.png';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import UpTriangle from '../../../assets/images/upTriangle.png'
import DownTriangle from '../../../assets/images/downTriangle.png'

const CITY_IMAGES = {
  "Royal Challengers Bangalore ": Bangalore,
  "Chennai Super Kings": Chennai,
  "Delhi Daredevils": NCR,
  "Salted Storm Pune": Pune,
  "Mumbai Paltan": Mumbai,
  "Hyderabadi Nawaabs": HYD,
};

const CityLeagueStandings = ({standingData}) => {
  return (
    <div className='city-league-sites-container'>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} className='city-league-sites-grid-container'>
          <Grid item xs={2.5} sm={2}>
            <div>
              <img src={cityLeague} alt="league" className="city-league-image" />
            </div>
          </Grid>
          <Grid item xs={9.5} sm={9}>
            <div className='city-league-sites-title'>CITY LEAGUE STANDING</div>
            <div className='city-league-sites-sub-title'> {standingData.standingData && standingData.standingData[0]?.Week.toUpperCase()}</div>
          </Grid>
        </Grid>
      </Box>
      <div className='city-league-sites-table-container'>
        {standingData.loading ? <CircularProgress /> : (
          <table className='city-league-sites-table'>
            <thead>
              <tr className='city-league-sites-table-row-title'>
                <th></th>
                <th></th>
                <th>40%</th>
                <th>20%</th>
                <th>15%</th>
                <th>25%</th>
                <th>TOTAL</th>
              </tr>
              <tr className='city-league-sites-table-row'>
                <th>RANK</th>
                <th>TEAMS</th>
                <th>REVENUE</th>
                <th>OTA</th>
                <th>WORKSHOP</th>
                <th>MARGINS</th>
                <th>SCORE</th>
              </tr>
            </thead>
            <tbody>
              {standingData.standingData
                .sort((a, b) => a["Rank Today"] - b["Rank Today"]) // Sort by "Rank Today"
                .map((item, index) => (
                  <tr key={index}>
                    <td style={{ borderRight: "4px solid black" }}>{item["Rank Today"]}</td>
                    <td className='city-league-sites-table-col-sl' style={{ borderRight: "4px solid black" }}>
                      <span className="city-image-container">
                        <img src={CITY_IMAGES[item.City]} alt={item.City} className="city-image" />
                      </span>
                      {item.City}
                      <span className="city-trend">
                      {item.Trend==="down" ? 
                      <img src={DownTriangle} className="city-trend-image" alt={item.City} />:
                      item.Trend==="up" ?
                      <img src={UpTriangle} className="city-trend-image" alt={item.City} />
                      : null}
                      </span>
                    </td>
                    <td className='data-cell'>{item.Revenue}</td>
                    <td className='data-cell-grey'>{item.OTA}</td>
                    <td className='data-cell'>{item["Vehicle Days in Workshop"]}</td>
                    <td className='data-cell-grey'>{item["City Level Margin"]}</td>
                    <td className='data-total-cell'><div>{item["Total score "]}</div></td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CityLeagueStandings;
